dmx.Component('array', {

    initialData: {
        items: [],
        count: 0
    },

    attributes: {
        items: {
            type: Array,
            default: []
        }
    },

    events: {
        updated: Event
    },

    methods: {
        add: function(newItem) {
            this.splice(this.count(), 0, newItem);
        },

        addUniq: function(newItem) {
            // Only add when not exists
            if (this.indexOf(newItem) == -1) {
                this.splice(this.count(), 0, newItem);
            }
        },

        insert: function(index, newItem) {
            this.splice(index, 0, newItem);
        },

        insertBefore: function(item, newItem) {
            var index = this.indexOf(item);
            if (index != -1) {
                this.splice(index, 0, newItem);
            }
        },

        insertAfter: function(item, newItem) {
            var index = this.indexOf(item);
            if (index != -1) {
                this.splice(index + 1, 0, newItem);
            }
        },

        replace: function(item, newItem) {
            var index = this.indexOf(item);
            if (index != -1) {
                this.splice(index, 1, newItem);
            }
        },

        replaceAt: function(index, newItem) {
            this.splice(index, 1, newItem);
        },

        remove: function(item) {
            var index = this.indexOf(item);
            if (index != -1) {
                this.splice(index, 1);
            }
        },

        removeAt: function(index) {
            this.splice(index, 1);
        },

        reverse: function() {
            this.reverse();
        },

        sort: function() {
            this.sort();
        }
    },

    render: function() {
        var arr = dmx.array(this.props.items);
        this.set('items', arr);
        this.set('count', arr.length);
    },

    update: function(props) {
        if (JSON.stringify(props.items) != JSON.stringify(this.props.items)) {
            this.updateData(dmx.array(this.props.items));
        }
    },

    count: function() {
        return this.data.items.length;
    },

    indexOf: function(item) {
        return this.data.items.indexOf(item);
    },

    splice: function(index, remove, item) {
        var arr = dmx.clone(this.data.items);

        if (item !== undefined) {
            arr.splice(index, remove, item);
        } else {
            arr.splice(index, remove);
        }

        this.updateData(arr);
    },

    reverse: function() {
        var arr = dmx.clone(this.data.items);
        arr.reverse();
        this.updateData(arr);
    },

    sort: function() {
        var arr = dmx.clone(this.data.items);
        arr.sort();
        this.updateData(arr);
    },

    updateData: function(arr) {
        if (JSON.stringify(this.data.items) != JSON.stringify(arr)) {
            this.set('items', arr);
            this.set('count', arr.length);
            dmx.nextTick(function() {
                this.dispatchEvent('updated');
            }, this);
        }
    }

});
